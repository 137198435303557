import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/styles";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { grey } from "@mui/material/colors";
import { Typography } from "@mui/material";

export default function Footer() {
  const theme = useTheme();

  const menuLinkContentObj = {
    Loans: [
      { name: "Get loan quote", route: "/loans" },
      {
        name: "Our loan terms",
        route: "/capital",
      },
    ],
    Sell: [{ name: "Sell property", route: "/sell" }],
    Listings: [{ name: "Our listings", route: "/listings" }],
    // Listings: [{ name: "Our listings", route: "/listings" }],
    Wholesalers: [
      { name: "Wholesalers", route: "/wholesalers" },
      { name: "List a wholesale deal", route: "/sell" },
    ],
    Resources: [
      { name: "Blog", route: "/blog" },
      { name: "Guides", route: "/guides" },
      { name: "Tools", route: "/tools" },
      { name: "Glossary", route: "/real-estate-glossary" },
      { name: "Markets", route: "/markets" },
      { name: "Referrals", route: "/referral-program" },
      { name: "Insurance", route: "/insurance" },
    ],
    Company: [
      { name: "About", route: "/about" },
      { name: "Contact", route: "/contact" },
    ],
  };
  return (
    <footer
      key={"footer"}
      style={{
        margin: "0",
        fontSize: "14px",
        [theme.breakpoints.down("md")]: {
          fontSize: "20px",
        },
        backgroundColor: "whitesmoke",
        height: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        key={"footerNavRow_1"}
        sx={{
          justifyContent: "space-around",
          padding: "2% 10% 1% 10%",
          width: "100%",
          display: "flex",
          flex: "1 0 auto",
          flexDirection: "row",
          color: `${theme.palette.primary.main} !important`,
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            paddingTop: "5%",
            paddingLeft: "14%",
          },
        }}
      >
        {Object.keys(menuLinkContentObj).map((item, i) => {
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
                [theme.breakpoints.down("md")]: {
                  marginBottom: "25px",
                },
              }}
              key={`${i}_footerNavSection_${item}`}
            >
              <Box
                sx={{
                  fontWeight: 600,
                  fontSize: "1rem",
                  marginBottom: "5px",
                }}
                key={`${i}_header_${item}`}
              >
                {item}
              </Box>
              {menuLinkContentObj[item].map((childLink, index) => {
                return (
                  <Box
                    key={`${i}_${index}_footer_links`}
                    sx={{
                      margin: theme.spacing(1, 0),
                    }}
                  >
                    <Typography
                      as="a"
                      href={childLink.route}
                      target={"_blank"}
                      sx={{
                        color: process.env.primaryBrandColor,
                        textDecoration: "none",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      {childLink.name}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          );
        })}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: "0 1 31%",
            padding: "0%",
            borderRadius: "5px",
            textAlign: "left",
            fontSize: theme.spacing(1.6),
          }}
        >
          <Box
            sx={{
              padding: "4px 0",
              textAlign: "left",
              borderRadius: "5px",
              padding: theme.spacing(0.5),
              [theme.breakpoints.down("md")]: {
                textAlign: "left",
                width: "100%",
                padding: "2% 15% 2% 15%",
              },
            }}
            as={"span"}
          >
            OfferMarket is a private lender and marketplace for assignment of
            contract and for sale by owner listings. By accessing and utilizing
            our services, you understand, acknowledge and agree that neither
            OfferMarket nor any of its representatives makes any representation
            or warranty, express or implied, as to the accuracy or completeness
            of any listings.​ You are expected to conduct your own due
            diligence.
          </Box>
          <Box
            sx={{
              padding: "1%",
              textAlign: "left",
              [theme.breakpoints.down("md")]: {
                textAlign: "left",
                width: "100%",
                padding: "2% 15% 2% 15%",
              },
              "& a": {
                color: "#0000FF",
              },
            }}
            as={"span"}
          >
            If you are having trouble reading this website or having screen
            reader issues, please call OfferMarket at{" "}
            <Link
              sx={{ color: theme.palette.highContrastLink.main }}
              rel="noopener"
              target="_blank"
              href="tel:+14434929941"
            >
              1-443-492-9941
            </Link>
            or email{" "}
            <Link
              sx={{ color: theme.palette.highContrastLink.main }}
              rel="noopener"
              target="_blank"
              href="mailto:hello@offermarket.us"
            >
              hello@offermarket.us
            </Link>
            . Our full{" "}
            <Link
              sx={{ color: theme.palette.highContrastLink.main }}
              rel="noopener"
              target="_blank"
              href="/accessibility-statement"
            >
              accessibility statement
            </Link>
            .
          </Box>
          <Box
            sx={{
              padding: "1%",
              textAlign: "left",
              [theme.breakpoints.down("md")]: {
                textAlign: "left",
                width: "100%",
                padding: "2% 15% 2% 15%",
              },
              "& a": {
                color: "#0000FF",
              },
            }}
            as={"span"}
          >
            By searching, signing up or using our site in any manner, you agree
            to the{" "}
            <Link
              sx={{ color: theme.palette.highContrastLink.main }}
              rel="noopener"
              target="_blank"
              href="/terms-of-service"
            >
              Terms of Service
            </Link>
            , and{" "}
            <Link
              sx={{ color: theme.palette.highContrastLink.main }}
              rel="noopener"
              target="_blank"
              href="/privacy-policy"
            >
              Privacy Policy
            </Link>
            .
          </Box>
          <Box
            sx={{
              padding: "1%",
              textAlign: "right",
              marginTop: "auto",
              [theme.breakpoints.down("md")]: {
                textAlign: "left",
                width: "100%",
                padding: "1% 15% 3% 15%",
              },
            }}
            as={"span"}
          >
            <a
              title="OfferMarket on facebook"
              target="_blank"
              href="https://www.facebook.com/OfferMarketUS"
            >
              <FacebookIcon sx={{ fontSize: 47, color: grey[500] }} />
            </a>
            <a
              title="OfferMarket on instagram"
              target="_blank"
              href="https://www.instagram.com/offermarketus"
            >
              <InstagramIcon sx={{ fontSize: 47, color: grey[500] }} />
            </a>
            <a
              title="OfferMarket on twitter"
              target="_blank"
              href="https://twitter.com/OfferMarketUS"
            >
              <TwitterIcon sx={{ fontSize: 47, color: grey[500] }} />
            </a>
            <a
              title="OfferMarket on youtube"
              target="_blank"
              href="https://www.youtube.com/channel/UCRoNHXFVgI36SV0QQpyf9fw"
            >
              <YouTubeIcon sx={{ fontSize: 47, color: grey[500] }} />
            </a>
          </Box>
          <Box
            sx={{
              padding: "1%",
              textAlign: "right",
              marginTop: "auto",
              [theme.breakpoints.down("md")]: {
                textAlign: "left",
                width: "100%",
                padding: "10% 15% 12% 15%",
              },
            }}
            as={"span"}
          >
            Copyright OfferMarket 2024
          </Box>
        </Box>
      </Box>
    </footer>
  );
}
